export const SET_AUDIO_PLAYER = "SET_AUDIO_PLAYER";
export const SET_VIDE_PLAYER = "SET_VIDE_PLAYER";
export const SET_PLAY_STATUS = "SET_PLAY_STATUS";
export const SET_FULL_SCREEN_STATUS = "SET_FULL_SCREEN_STATUS";
export const SET_PLAYBACK_RATE = "SET_PLAYBACK_RATE";
export const SET_VOLUME = "SET_VOLUME";
export const SET_STREAM_VALUES = "SET_STREAM_VALUES";
export const SET_STREAM_METADATA = "SET_STREAM_METADATA";
export const SET_STREAM_QUALITY = "SET_STREAM_QUALITY";
export const SET_AVAILABLE_QUALITIES = "SET_AVAILABLE_QUALITIES";
export const SET_STREAM_SOURCE = "SET_STREAM_SOURCE";
export const SET_QUALITY_UPDATE_STATUS = "SET_QUALITY_UPDATE_STATUS";
export const SET_STREAM_PLAYED = "SET_STREAM_PLAYED";
export const SET_COMMENT_DATA = "SET_COMMENT_DATA";
export const SET_PREV_PROGRESS = "SET_PREV_PROGRESS";
export const SET_STREAM_UUID = "SET_STREAM_UUID";
export const SET_STREAM_LOADING = "SET_STREAM_LOADING";
export const SET_AUTO_PLAY_REQUEST = "SET_AUTO_PLAY_REQUEST";